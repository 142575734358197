import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useResizeObserver from '../hooks/useResizeObserver';

export const SmoothScrollContext = createContext({
    scroll: null,
});

const defaultOptions = {
    smooth: true,
    scrollFromAnywhere: true,
};

export const SmoothScrollProvider = ({ children, options }) => {
    const [scroll, setScroll] = useState(null);
    const location = useLocation();

    const [rootHeight, setRootHeight] = useState(0);
    const [mainWidth, setMainWidth] = useState(0);

    useResizeObserver({
        callback: observe => setRootHeight(observe[0].contentRect.height),
        element: { current: document.getElementById('root') }
    });

    useResizeObserver({
        callback: observe => setMainWidth(observe[0].contentRect.width),
        element: { current: document.querySelector('main') }
    });

    useEffect(() => {
        scroll && scroll.update();
    }, [rootHeight, mainWidth]); // eslint-disable-line

    useEffect(() => {
        scroll && scroll.scrollTo(0);
    }, [location.pathname]); // eslint-disable-line

    const wheelHandler = event => {
        if(event?.target?.className !== 'scroll-content') {
            window?.scrollBy({
                left: document?.body?.scrollLeft + event?.deltaY,
            });
        }
    }

    useEffect(() => {
        document.documentElement.classList[options?.direction === 'horizontal' ? 'add' : 'remove']('has-horizontal-scroll');
        if(options?.direction === 'horizontal' && scroll) {
             scroll?.destroy();
             window?.addEventListener('wheel', wheelHandler);
        }
        return () => {
            if(options?.direction === 'horizontal' && scroll) {
                window?.removeEventListener('wheel', wheelHandler);
            }
        }
    }, [options?.direction, scroll]);

    useEffect(() => {
        if (!scroll) {
            ;(async () => {
                try {
                    const LocomotiveScroll = (await import('locomotive-scroll')).default;

                    setScroll(
                        new LocomotiveScroll({
                            el: document.querySelector('[data-scroll-container]') ?? undefined,
                            ...defaultOptions,
                            ...options,
                            tablet: {
                                breakpoint: 1024,
                                smooth: false,
                            }
                        })
                    );
                } catch (error) {
                    throw Error(`[SmoothScrollProvider]: ${error}`);
                }
            })();
        }

        return () => {
            scroll && scroll.destroy();
        };
    }, [scroll]); // eslint-disable-line

    return (
        <SmoothScrollContext.Provider
            value={{ scroll }}
        >
            {children}
        </SmoothScrollContext.Provider>
    );
};

SmoothScrollContext.displayName = 'SmoothScrollContext';
SmoothScrollProvider.displayName = 'SmoothScrollProvider';
