import { lazy } from 'react';

const CaseStudy = lazy(() => import('../containers/caseStudy').then());
const Category = lazy(() => import('../containers/category').then());
const Landing = lazy(() => import('../containers/landing').then());

const routes = [
    {
        url: '/',
        component: <Landing/>,
    },
    {
        url: '/:category',
        component: <Category/>,
    },
    {
        url: '/:category/:item',
        component: <CaseStudy/>,
        scrollOptions: {
            direction: 'horizontal',
            gestureDirection: 'both',
        }
    },
];

export default routes;
