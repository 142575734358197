import React, { useRef, useState, useEffect, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import SuspenseWrapper from './components/suspenseWrapper';

import useWindowSize from './hooks/useWindowSize';
import useFullLocation from './hooks/useFullLocation';

import SharedContext from './contexts/sharedContext';
import { SmoothScrollProvider } from './contexts/scroll';

import routes from './routes';

import { defaultMeta } from './constants/config';

import './assets/scss/main.scss';
import './assets/scss/locomotive.scss';

const Aside = lazy(() => import('./components/aside').then());
const Cursor = lazy(() => import('./components/cursor').then());
const Loader = lazy(() => import('./components/loader').then());
const Navigation = lazy(() => import('./components/navigation').then());

const fallbackRoute = routes[0];

const App = () => {
    const { height, heightOnce } = useWindowSize();
    const cursorRef = useRef(null);
    const [mode, setMode] = useState('light');
    const [authenticated, setAuthenticated] = useState(false);
    const [openAuthentication, setOpenAuthentication] = useState(false);
    const [authRedirect, setAuthRedirect] = useState({
        url: '',
        text: '',
    });
    const [meta, setMeta] = useState(defaultMeta);
    const [triggerURLScroll, setTriggerURLScroll] = useState(false);
    const [scroll, setScroll] = useState(null);
    const [loading, setLoading] = useState({
        active: true,
        cursor: false,
        text: null,
    });
    const location = useFullLocation();

    useEffect(() => {
        document.documentElement.setAttribute('mode', mode);
    }, [mode]);

    useEffect(() => {
        document.documentElement.style.setProperty('--window-height', `${height}px`);
        document.documentElement.style.setProperty('--window-height-once', `${heightOnce}px`);
    }, [height, heightOnce]);

    return (
        <SharedContext.Provider
            value={{
                meta,
                scroll,
                setMeta,
                loading,
                setMode,
                cursorRef,
                setScroll,
                setLoading,
                authRedirect,
                authenticated,
                setAuthRedirect,
                setAuthenticated,
                triggerURLScroll,
                openAuthentication,
                setTriggerURLScroll,
                setOpenAuthentication,
            }}
        >
            <SuspenseWrapper>
                <Cursor/>
            </SuspenseWrapper>
            <SuspenseWrapper>
                <Loader/>
            </SuspenseWrapper>
            <SuspenseWrapper>
                <Navigation/>
            </SuspenseWrapper>
            <main>
                <Switch>
                    {routes.map(route => (
                        <Route
                            exact
                            key={route.url}
                            path={route.url}
                        >
                            <SmoothScrollProvider options={route.scrollOptions}>
                                <SuspenseWrapper updateScroll>
                                    {route.component}
                                </SuspenseWrapper>
                            </SmoothScrollProvider>
                        </Route>
                    ))}
                    <Redirect to={fallbackRoute.url}/>
                </Switch>
            </main>
            <SuspenseWrapper>
                <Aside/>
            </SuspenseWrapper>
            <Helmet>
                <title>{meta.title}</title>
                <meta content={meta.title} itemProp="name"/>
                <meta content={meta.title} property="og:title"/>
                <meta content={meta.title} name="twitter:title"/>
                <meta content={meta.title} name="title"/>
                <meta content={meta.title} property="og:image:alt"/>
                <meta content={meta.description} name="description"/>
                <meta content={meta.description} itemProp="description"/>
                <meta content={meta.description} property="og:description"/>
                <meta content={meta.description} name="twitter:description"/>
                <meta content={meta.keywords} name="keywords"/>
                <meta content={process.env.REACT_APP_URL + location} property="og:url"/>
                <meta content={process.env.REACT_APP_URL + location} property="twitter:url"/>
                <link href={process.env.REACT_APP_URL + location} rel="canonical"/>
                <link href={process.env.REACT_APP_URL + location} rel="alternate" hrefLang="x-default"/>
                <meta itemProp="image" content={meta.image.source}/>
                <meta property="og:image" content={meta.image.source}/>
                <meta name="twitter:image" content={meta.image.source}/>
                <meta property="og:image:width" content={meta.image.width}/>
                <meta property="og:image:height" content={meta.image.height}/>
            </Helmet>
        </SharedContext.Provider>
    );
};

export default App;
