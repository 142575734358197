import { useState, useEffect } from 'react';

const getSize = val => ({
    width: window.innerWidth,
    height: window.innerHeight,
    heightOnce: (val?.heightOnce + 150) > window.innerHeight ? val?.heightOnce : window.innerHeight,
});

const useWindowSize = () => {

    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        const handleResize = () => setWindowSize(val => getSize(val));

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
