import archiveGraph from './media/archive.jpg';
import developmentGraph from './media/development.jpg';
import editingGraph from './media/editing.jpg';
import logoGraph from './media/logo.jpg';
import printingGraph from './media/printing.jpg';
import productionGraph from './media/production.jpg';
import uiUxGraph from './media/uiUx.jpg';

export const defaultMeta = {
    title: process.env.REACT_APP_TITLE,
    description: process.env.REACT_APP_DESCRIPTION,
    keywords: process.env.REACT_APP_KEYWORDS,
    image: {
        source: `${process.env.REACT_APP_URL}/assets/branding/graph.jpg`,
        width: 1200,
        height: 1200,
    },
};

export const categories = [
    {
        id: 1,
        url: '/ui-ux',
        label: 'UI/UX',
        order: 1,
        priority: 0.5,
        alternativeLabel: 'ui/ux',
        meta: {
            description: 'Showcase of UI/UX works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: uiUxGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 2,
        url: '/development',
        label: 'Development',
        order: 0,
        priority: 0.5,
        alternativeLabel: 'development',
        meta: {
            description: 'Showcase of Development works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: developmentGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 3,
        url: '/archive',
        label: 'Archive',
        order: 6,
        priority: 0.5,
        alternativeLabel: 'archive',
        meta: {
            description: 'Showcase of Archived works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: archiveGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 4,
        url: '/logo',
        label: 'Logo',
        order: 2,
        priority: 0.5,
        alternativeLabel: 'logo',
        meta: {
            description: 'Showcase of Logos created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: logoGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 5,
        url: '/printing',
        label: 'Printing',
        order: 4,
        priority: 0.5,
        alternativeLabel: 'printing',
        meta: {
            description: 'Showcase of Printing works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: printingGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 6,
        url: '/editing',
        label: 'Editing',
        order: 3,
        priority: 0.5,
        alternativeLabel: 'editing',
        meta: {
            description: 'Showcase of Photo Editing works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: editingGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
    {
        id: 7,
        url: '/production',
        label: 'Production',
        order: 5,
        priority: 0.5,
        alternativeLabel: 'production',
        meta: {
            description: 'Showcase of Video Editing works created by Clyne',
            keywords: process.env.REACT_APP_KEYWORDS,
            image: {
                source: productionGraph,
                width: 1200,
                height: 1200,
            },
        },
    },
];

export const contacts = [
    {
        label: 'Email',
        href: 'mailto:j@clyne.design',
    },
    {
        label: 'About',
        href: process.env.REACT_APP_LINKEDIN,
    },
];

export const navigation = [
    {
        label: 'Intro',
        url: '/',
        alternativeLabel: 'clyne',
        meta: defaultMeta,
    },
    {
        label: 'Portfolio',
        url: '/#portfolio',
        alternativeLabel: 'portfolio',
        isActive: location => '/#portfolio' === location.pathname + location.hash || categories.some(cat => cat.url === location.pathname || location.pathname.includes(cat.url)),
        meta: {
            title: 'Portfolio',
            description: 'Showcase of UI/UX, Development, Graphic design and Media related works created by Clyne Design. Made with dedication and love.',
            keywords: process.env.REACT_APP_KEYWORDS,
        },
    },
];

export const toConfig = {
    target: [
        '_blank',
        '_self',
        '_parent',
        '_top',
    ],
};

export const introTypingValues = [
    'mesmerizing',
    'astonishing',
    'astounding',
    'surprising',
    'bewildering',
    'stunning',
    'staggering',
    'shocking',
    'startling',
    'stupefying',
    'breathtaking',
    'perplexing',
    'confounding',
    'dismaying',
    'disconcerting',
    'shattering',
    'awesome',
    'awe-inspiring',
    'sensational',
    'remarkable',
    'spectacular',
    'stupendous',
    'phenomenal',
    'prodigious',
    'extraordinary',
    'incredible',
    'unbelievable',
    'wonderful',
    'marvellous',
    'thrilling',
    'exciting',
    'mind-blowing',
    'flabbergasting',
    'wondrous',
].reduce((acc, val) => [...acc, val, 2000], []);

export const confidentialPatternConfig = {
    color: [
        'light',
        'dark',
        'variable',
    ],
};

export const unitedRoutes = [...navigation, ...categories];
