import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';

import useScrollContext from '../../hooks/useScrollContext';
import useSharedContext from '../../hooks/useSharedContext';

const SuspenseWrapper = props => {
    const {
        children,
        updateScroll,
    } = props;

    const { scroll } = useScrollContext();
    const { setScroll } = useSharedContext();

    useEffect(() => {
        (!!scroll && updateScroll) && setScroll(scroll);
    }, [scroll, updateScroll]); // eslint-disable-line

    return (
        <Suspense fallback={null}>
            {children}
        </Suspense>
    );
};

SuspenseWrapper.propTypes = {
    children: PropTypes.any,
    updateScroll: PropTypes.bool,
};

export default SuspenseWrapper;
