import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';

import './assets/scss/reset.scss';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <HelmetProvider>
                <App/>
            </HelmetProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
